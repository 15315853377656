import { TextileThemeLayout, TextileThemeMotto } from 'api/textile_deals/fetchTextileThemes';
import ButtonBlock from 'blocks/ButtonBlock';
import CardBlock from 'blocks/CardBlock';
import {
  ImageCard,
  ImageCardLabel,
  LabelledImageCard
} from 'blocks/ImageCard/ImageCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import PaddingBlock from 'blocks/PaddingBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import TwoColumnBlock from 'blocks/TwoColumnBlock';
import ConfirmBoxComponent from 'components/ConfirmBoxComponent/ConfirmBoxComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import BackToTextileDashboardLink from 'domain/Links/BackToTextileDashboardLink';
import Headline from 'elements/Headline';
import { useOptimisticUpdateTextileDesignSettingQuery, useTextileDesignSettingQuery } from 'queries/textile_deals/useTextileDesignSettingQueries';
import { useTextileThemesQuery } from 'queries/textile_deals/useTextileThemesQueries';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import TextileProductImageComponent from 'screens/preorders/components/TextileProductImageComponent';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import { previewImage } from 'utils/preview_image';
import { ProductImage } from 'utils/product-image';
import {
  mottoThemes,
  neutralThemes,
  privateThemes
} from 'utils/textile-theme-category';
import textileThemesImagePath from 'utils/textile-theme-image-path';

interface ThemeProps {
  detail?: boolean;
}

const TextileDesignThemes: React.FC<HistoryProps & ThemeProps> = (props) => {
  const [ confirmUpdate, setConfirmUpdate] = useState<boolean>(false);
  const [ selectedTheme, setSelectedTheme] = useState<TextileThemeLayout | undefined>(undefined);
  const textileOrderId = textileOrderIdFromMatch(props.match);

  const accessContext = useContext(HasAccessContext);
  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  const textileDesignSetting = useTextileDesignSettingQuery(textileOrderId);
  const textileThemes = useTextileThemesQuery(textileOrderId);
  const updateTextileDesignSettings = useOptimisticUpdateTextileDesignSettingQuery(textileOrderId);


  useEffect(() => {
    accessContext.checkSectionAccess('design');
  }, [accessContext]);

  const renderPage = (content: any) => {
    let link;

    if (props.detail) {
      link = (
        <BackLink
          to={textileOrderRoute(props.match, '/textile_design/themes')}
        />
      );
    } else {
      const settings = textileDesignSetting.data;

      if (!settings || !settings.layout_key) {
        link = (
          <BackToTextileDashboardLink
            link={textileOrderRoute(props.match, '')}
          />
        );
      } else {
        link = (
          <BackLink to={textileOrderRoute(props.match, '/textile_design')} />
        );
      }
    }

    return (
      <>
        <TopActionBlock>{link}</TopActionBlock>

        <PageStackBlock>{content}</PageStackBlock>
      </>
    );
  };

  if (textileDesignSetting.isError) {
    return (
      renderPage(<GenericErrorComponent
        onRetryClick={() => textileDesignSetting.refetch()}
      />)
    );
  }

  if (textileThemes.isError) {
    return (
      renderPage(<GenericErrorComponent onRetryClick={() => textileThemes.refetch()} />)
    );
  }

  if (
    textileThemes.isLoading ||
    !textileThemes.data ||
    textileDesignSetting.isLoading ||
    !textileDesignSetting.data
  ) {
    return renderPage(<LoadingIndicatorComponent />)
  }

  const isNeutralActive = () => {
    return props.location?.hash === '#neutral';
  };

  const prepareThemeUpdate = (theme: TextileThemeLayout) => {
    const settings = textileDesignSetting.data;

    if (!settings || !themeExists(theme.key)) {
      // wtf
      return undefined;
    }

    return theme;
  }

  const doesUpdateResetSettings = (oldKey: string, newKey: string) => {
    if (oldKey === newKey) {
      return false;
    }

    const oldIdx = oldKey.indexOf('_');
    const newIdx = newKey.indexOf('_');
    if (oldIdx < 0 || newIdx < 0 || oldIdx !== newIdx) {
      // no base theme key, we don't know what is going on here
      // or length of base theme name is different, so we don't need to compare them to know they are different
      return true;
    }

    if (oldKey.substring(0, oldIdx) !== newKey.substring(0, newIdx)) {
      // different base theme names, update will reset all layout settings
      return true;
    }

    return false;
  }

  const selectTheme = (theme: TextileThemeLayout) => {

    const textileOrderId = textileOrderIdFromMatch(props.match);

    if (!textileOrderId) {
      return;
    }

    const settings = textileDesignSetting.data;

    const patch = {...settings, theme, layout_key: theme.key}

    updateTextileDesignSettings.mutate({data: patch, textileOrderId}, {
      onSuccess: () => {
        props.history.push(textileOrderRoute(props.match, '/textile_design'));
      }
    })
  }

  const beginSelectTheme = (theme: TextileThemeLayout) => {
    const settings = textileDesignSetting.data;
    const key = settings.layout_key;


    if (theme.key === settings.layout_key) {
      // nothing changed
      props.history.push(
        textileOrderRoute(props.match, '/textile_design')
      );
      return;
    }

    if (
      !key ||
      !doesUpdateResetSettings(theme.key, key)
    ) {
      // first time selecting a theme or keeping same base theme, all good
      selectTheme(theme);
      return;
    }

    setConfirmUpdate(true);
    setSelectedTheme(theme)
  }

  const finishConfirmUpdate = (performSelect: boolean = false) => {
    if (performSelect && selectedTheme) {
      const theme = prepareThemeUpdate(selectedTheme);
      if (theme) {
        selectTheme(selectedTheme);
      }
    }

    setConfirmUpdate(false);
    setSelectedTheme(undefined)
  }

  const renderList = () => {
    const privateThemesList = !isNeutralActive()
      ? privateThemes(textileThemes.data.themes)
      : [];

    const currentThemeId = textileDesignSetting.data?.layout_key;

    return renderPage(
      <>
        <Headline.Large>
          <FormattedMessage id="choose theme textiles" />
        </Headline.Large>

        <Paragraph>
          <FormattedMessage id="choose theme info textiles" />
        </Paragraph>

        {isNeutralActive() ? (
          <>
            {neutralThemes(textileThemes.data.themes).map((theme) => {
              return (
                <Link
                  key={theme.key}
                  to={textileOrderRoute(
                    props.match,
                    '/textile_design/theme' + theme.key
                  )}
                >
                  <CardBlock>
                    <PaddingBlock arrow={true}>
                      <Headline.Small color="GRAY10">
                        {theme.name || theme.key}
                      </Headline.Small>
                    </PaddingBlock>
                  </CardBlock>
                </Link>
              );
            })}
          </>
        ) : (
          <>
            {!!privateThemesList.length && (
              <ItemStackBlock gap="S">
                <Headline.Small line={true}>
                  <FormattedMessage id="Individual themes" />
                </Headline.Small>

                <TwoColumnBlock gap="M">
                  {privateThemesList.map((theme) => {
                    const preview: ProductImage = previewImage(
                      textileThemesImagePath(
                        theme.key,
                        theme.frontPreview_organicHoodie
                      )
                    );

                    return (
                      <Link
                        to={textileOrderRoute(
                          props.match,
                          '/textile_design/theme' + theme.key
                        )}
                        key={theme.key}
                      >
                        <LabelledImageCard>
                          <ImageCard
                            contain={true}
                            border={
                              currentThemeId === theme.key ? 'PRIMARY' : false
                            }
                          >
                            <TextileProductImageComponent
                              productImage={preview}
                            />
                          </ImageCard>
                          <ImageCardLabel>
                            {theme.name || theme.key}
                          </ImageCardLabel>
                        </LabelledImageCard>
                      </Link>
                    );
                  })}
                </TwoColumnBlock>
              </ItemStackBlock>
            )}

            {mottoThemes(
              textileThemes.data.themes,
              textileThemes.data.mottos
            ).map((item) => {
              if (item.themes.length === 0) {
                return null;
              }

              return (
                <ItemStackBlock key={item.motto.key} gap="S">
                  <Headline.Small>{item.motto.name}</Headline.Small>

                  <TwoColumnBlock gap="M">
                    {item.themes.map((theme) => {
                      const preview: ProductImage = previewImage(
                        textileThemesImagePath(
                          theme.key,
                          theme.frontPreview_organicHoodie
                        )
                      );
                      return (
                        <Link
                          to={textileOrderRoute(
                            props.match,
                            '/textile_design/theme/' + theme.key
                          )}
                          key={theme.key}
                        >
                          <ImageCard
                            contain={true}
                            border={
                              currentThemeId === theme.key ? 'PRIMARY' : false
                            }
                          >
                            <TextileProductImageComponent
                              productImage={preview}
                            />
                          </ImageCard>
                        </Link>
                      );
                    })}
                  </TwoColumnBlock>
                </ItemStackBlock>
              );
            })}
          </>
        )}
      </>
    );
  };

  const themeExists = (themeKey: string): TextileThemeLayout | undefined => {
    return textileThemes.data.themes.find((theme) => theme.key === themeKey)
  }

  const renderDetail = () => {
        const { match } = props;
        const themeKey = match.params?.key;

        if (!themeKey) {
          return renderList();
        }

        const theme = themeExists(themeKey);

        if (!theme) {
          return renderList();
        }
        let mottoName: string | undefined;
        const mottoTheme: TextileThemeMotto | undefined = mottoThemes(textileThemes.data.themes, textileThemes.data.mottos).find((mottoTheme) => mottoTheme.themes.find((itmTheme) => itmTheme.key === theme.key))?.motto
        if (mottoTheme) {
          mottoName = mottoTheme.name
        }

        const preview: ProductImage = previewImage(
          textileThemesImagePath(theme.key, theme.frontPreview_organicHoodie)
        );

        // TODO preview
        return renderPage(
          <>
            <PageHeader
              headline={mottoName || theme.name || <FormattedMessage id="Design theme" />}
              text={<FormattedMessage id="textile design theme detail" />}
            />

            {theme.frontPreview_organicHoodie && (
              <TextileProductImageComponent productImage={preview}
              width="100%"
              />
            )}

            <ButtonBlock
              background="PRIMARY"
              onClick={() => beginSelectTheme(theme)}
            >
              <FormattedMessage id="Select this theme" />
            </ButtonBlock>

            {confirmUpdate && (
              <ConfirmBoxComponent
                header={<FormattedMessage id="theme confirm header" />}
                text={<FormattedMessage id="textile theme confirm text" />}
                confirmText={<FormattedMessage id="Confirm" />}
                confirmColor="SECONDARY_DARK"
                abortText={<FormattedMessage id="Cancel" />}
                onConfirm={() => finishConfirmUpdate(true)}
                onAbort={() => finishConfirmUpdate(false)}
              />
            )}
          </>
        );
      }

  if (props.detail) {
    return renderDetail();
  }
  return renderList();
};

export default TextileDesignThemes;
