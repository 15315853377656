import React, { useContext, useEffect } from 'react';

import { TextileOrderForManagement } from 'api/textile_deals/fetchTextileOrders';
import CustomCheckboxBlock from 'blocks/CustomCheckboxBlock';
import { EveryCard, EveryCardBody, EveryCardHeadline, EveryCardPadding } from 'blocks/EveryCard/EveryCard';
import ItemStackBlock from 'blocks/ItemStackBlock';
import TopActionBlock from 'blocks/TopActionBlock';
import Divider from 'components/Divider/Divider';
import FormStackBlock from 'components/FormStackBlock/FormStackBlock';
import IconComponent from 'components/IconComponent';
import GenericErrorComponent from 'components/InfoBoxComponent/GenericErrorComponent';
import LoadingIndicatorComponent from 'components/LoadingIndicatorComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import PageStackBlock from 'components/PageStackBlock/PageStackBlock';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import { FlashMessageContext } from 'contexts/FlashMessageContext';
import { HasAccessContext } from 'contexts/HasAccessContext';
import BackLink from 'domain/Links/BackLink';
import Headline from 'elements/Headline';
import { intl } from 'i18n';
import { useOptimisticUpdateTextileOrderQuery, useTextileOrderForManagementQuery } from 'queries/textile_deals/useTextileOrderQueries';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { HistoryProps } from 'utils/history';
import { textileOrderIdFromMatch } from 'utils/history/textile-order-id-from-match';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import useForm, { FormType } from 'utils/hooks/useForm';

interface FormProps {
  form: FormType;
}

const AllowEditPrintDataScreen: React.FC<HistoryProps & FormProps> = (props) => {
  const textileOrderId = textileOrderIdFromMatch(props.match);
  const accessContext = useContext(HasAccessContext);
  const flashMessageContext = useContext(FlashMessageContext);
  const textileOrder = useTextileOrderForManagementQuery(textileOrderId);
  const updateTextileOrder = useOptimisticUpdateTextileOrderQuery(textileOrderId);

  if (!accessContext) {
    throw Error('Component must be used within HasAccessContextProvider');
  }

  if (!flashMessageContext) {
    throw Error('Component must be used within HasFlashMessageProvider');
  }

  useEffect(() => {
    const data = textileOrder.data;
    if (data) {
      props.form.setField('allow_edit_print_data',
              data.allow_edit_print_data || false
            )
    }
    accessContext.checkSectionAccess('management');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessContext, textileOrder.data]);

  const renderPage = (content?: React.ReactNode) => {
    return (
      <>
        <TopActionBlock>
          <BackLink to={textileOrderRoute(props.match, '/management/')} />
        </TopActionBlock>

        <PageStackBlock>
          <PageHeader
            headline={<FormattedMessage id="textile editing phase" />}
            text={<FormattedMessage id="textile editing phase info" />}
          />

          {content}
        </PageStackBlock>
      </>
    );
  }

  if (textileOrder.isError) {
    return renderPage(<GenericErrorComponent onRetryClick={() => textileOrder.refetch()} />)
  }

  if (textileOrder.isLoading || !textileOrder.data) {
    return renderPage(<LoadingIndicatorComponent />)
  }

  const setPrintDataState = (allow: boolean) => {
    if (!textileOrderId || !textileOrder) {
      return;
    }

    console.log("allow print Data", allow, props.form.values.allow_edit_print_data);
    const patch: TextileOrderForManagement = {...textileOrder.data, allow_edit_print_data: allow}
    updateTextileOrder.mutate({data: patch, textileOrderId}, {
      onSuccess: () => {
        props.form.setField('allow_edit_print_data', allow)
        flashMessageContext.triggerFlashMessage(
          intl.formatMessage({ id: 'Saved' }),
          'success'
        );
      }
    })
  }


  const renderActivate = () => {
    const { form } = props;

    if (!textileOrder.data || form.values.allow_edit_print_data === undefined) {
      return null;
    }

    return renderPage(
      <>
        <FormStackBlock>
          <CustomCheckboxBlock>
            <CustomCheckboxBlock.CheckboxElement
              id="allow_edit_print_data"
              {...form.bindCheckbox('allow_edit_print_data')}
              onClick={(e: any) => setPrintDataState(e.target.checked)}
            />
            <CustomCheckboxBlock.LabelElement htmlFor="allow_edit_print_data">
              <FormattedMessage id="activate preorder deadline" />
            </CustomCheckboxBlock.LabelElement>
            {form.values.allow_edit_print_data ? (
              <TextElement size="S">
                <FormattedMessage id="textile edit allowed" />
              </TextElement>
            ) : (
              <TextElement size="S">
                <FormattedMessage id="textile edit not allowed" />
              </TextElement>
            )}
          </CustomCheckboxBlock>
        </FormStackBlock>

        {form.values.allow_edit_print_data && (
          <>
            <Divider />
            <ItemStackBlock gap="M">
              <Headline.Medium>
                <FormattedMessage id="Deadline" />
              </Headline.Medium>
              <Paragraph>
                <FormattedMessage id="preorder deadline info" />
              </Paragraph>
            </ItemStackBlock>

            <Link
              to={textileOrderRoute(
                props.match,
                '/management/edit_print_data/deadline'
              )}
            >
              <EveryCard>
                <EveryCardPadding>
                  <EveryCardBody>
                    <EveryCardHeadline>
                      {textileOrder.data.edit_print_data_deadline ? (
                        <TextElement>
                          <FormattedMessage id="preorder open til" />{' '}
                          <FormattedDate
                            value={textileOrder.data.edit_print_data_deadline}
                            year="numeric"
                            month="2-digit"
                            day="2-digit"
                          />
                        </TextElement>
                      ) : (
                        <FormattedMessage id="set preorder deadline" />
                      )}
                    </EveryCardHeadline>
                  </EveryCardBody>
                  <IconComponent icon="ARROW_RIGHT" fill="BLACK" size={1.5} />
                </EveryCardPadding>
              </EveryCard>
            </Link>
          </>
        )}
      </>
    );
  }

  return renderActivate()
}

export default (props: HistoryProps) => {
  const form = useForm();
  // @ts-ignore
  return <AllowEditPrintDataScreen {...props} form={form} />;
};
