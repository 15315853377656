import React, { useEffect } from 'react';

import { TextileOrderForCheckout } from 'api/textile_deals/fetchTextileOrders';
import ButtonBlock from 'blocks/ButtonBlock';
import CardBlock from 'blocks/CardBlock';
import CheckboxBlock from 'blocks/CheckboxBlock';
import {
  EveryCard,
  EveryCardBody,
  EveryCardHeadline,
  EveryCardPadding
} from 'blocks/EveryCard/EveryCard';
import PaddingBlock from 'blocks/PaddingBlock';
import IconComponent from 'components/IconComponent';
import PageHeader from 'components/PageHeader/PageHeader';
import Paragraph from 'components/Paragraph/Paragraph';
import TextElement from 'components/TextElement/TextElement';
import InputMessage from 'elements/InputMessage';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { textileOrderRoute } from 'utils/history/textile-order-route';
import { FormType } from 'utils/hooks/useForm';

interface TextilePrintingScreenProps {
  form: FormType;
  updatePrinting: () => void;
  generatePrint: () => void;
  checkForPreview: () => void;
  textileOrder: TextileOrderForCheckout;
  mockupPDF?: string;
}

const TextilePrintingScreen = ({
  form,
  updatePrinting,
  generatePrint,
  checkForPreview,
  textileOrder,
  mockupPDF
}: TextilePrintingScreenProps) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeader
        headline={<FormattedMessage id="Checkout print header" />}
        text={<FormattedMessage id="Checkout print info" />}
      />

      <Link
        to={textileOrderRoute(
          undefined,
          '/checkout/not_printed',
          textileOrder.id
        )}
      >
        <CardBlock>
          <PaddingBlock arrow={true}>
            <TextElement color="BLACK">
              <FormattedMessage id="textile checkout sources of errors" />
            </TextElement>
          </PaddingBlock>
        </CardBlock>
      </Link>

      {textileOrder.print_state === 'generated' && (
        <>
          <p>
            <a
              href={mockupPDF}
              download="nuggit-Vorschau.pdf"
              target="_blank"
              rel="noreferrer noopener"
            >
              <EveryCard>
                <EveryCardPadding>
                  <EveryCardBody>
                    <EveryCardHeadline>
                      <FormattedMessage id="textile checkout check print" />
                    </EveryCardHeadline>
                  </EveryCardBody>
                  <IconComponent icon="DOWNLOAD" fill="BLACK" size={2} />
                </EveryCardPadding>
              </EveryCard>
            </a>
          </p>

          <CheckboxBlock>
            <CheckboxBlock.CheckboxElement
              id="accept_print"
              {...form.bindCheckbox('accept_print')}
            />
            <CheckboxBlock.LabelElement htmlFor="accept_print">
              <FormattedMessage id="Checkout print Accept" />
            </CheckboxBlock.LabelElement>
          </CheckboxBlock>

          {form.values.accept_print ? (
            <ButtonBlock background="PRIMARY" onClick={() => updatePrinting()}>
              <FormattedMessage id="Save" />
            </ButtonBlock>
          ) : (
            <ButtonBlock
              background="GRAY950"
              color="GRAY800"
              disabled={true}
              onClick={() => updatePrinting()}
            >
              <FormattedMessage id="textile checkout print missing" />
            </ButtonBlock>
          )}
        </>
      )}

      {(textileOrder.print_state === 'print_open' ||
        textileOrder.print_state === 'generated') && (
        <ButtonBlock
          background={
            textileOrder.print_state === 'print_open'
              ? 'PRIMARY'
              : 'PRIMARY_LIGHT'
          }
          color={
            textileOrder.print_state === 'generated' ? 'PRIMARY_DARK' : 'WHITE'
          }
          onClick={() => generatePrint()}
        >
          <FormattedMessage id="Generate print textile" />
        </ButtonBlock>
      )}

      {(textileOrder.print_state === 'generating' || textileOrder.print_state === 'enqueued') && (
        <>
          <ButtonBlock background="SECONDARY" onClick={() => checkForPreview()}>
            <FormattedMessage id="Reload" />
          </ButtonBlock>

          <InputMessage warning={true}>
            <Paragraph>
              <FormattedMessage id="textile print is generating" />
            </Paragraph>
          </InputMessage>
        </>
      )}
    </>
  );
};
export default TextilePrintingScreen;
