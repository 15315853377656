import React from 'react';

import { ChapterTypeEnum } from 'models/ChapterTypeEnum';
import { HistoryProps } from 'utils/history';

import config from 'config';
import { createChapterModel } from 'models/ChapterModel';
import PrintExportErrorComponent from './PrintExportErrorComponent';
import PrintAlbumPageComponent from './chapters/PrintAlbumPageComponent';
import {
  ChapterPrintParams,
  CoverModeType
} from './chapters/PrintChapterProps';
import PrintCoverComponent from './chapters/PrintCoverComponent';
import PrintFactsheetComponent from './chapters/PrintFactsheetComponent';
import PrintImprintComponent from './chapters/PrintImprintComponent';
import PrintQuotesChapterComponent from './chapters/PrintQuotesChapterComponent';
import PrintRankingsChapterComponent from './chapters/PrintRankingsChapterComponent';
import PrintSponsorPageComponent from './chapters/PrintSponsorPageComponent';
import PrintTextChapterComponent from './chapters/PrintTextChapterComponent';
import PrintTocChapterComponent from './chapters/PrintTocChapterComponent';
import PrintYearbookChapterComponent from './chapters/PrintYearbookChapterComponent';

const BASE_PATH_DEV = '/print-export';
const BASE_PATH_PROD = process.env.NUGGIT_PRINT_BASE_URL || BASE_PATH_DEV;

type PrintChapterContainerProps = HistoryProps;

interface PrintChapterContainerState {
  loadingState?: 'loading' | 'error' | 'ready';
  params: ChapterPrintParams;
  data?: any;
}

export class PrintChapterContainer extends React.Component<
  PrintChapterContainerProps,
  PrintChapterContainerState
> {
  constructor(props: PrintChapterContainerProps) {
    super(props);

    this.state = {
      params: this.printParamsFromProps(props)
    };
  }

  componentDidMount() {
    this.loadChapter();
  }

  printParamsFromProps(props: PrintChapterContainerProps): ChapterPrintParams {
    const query = props.location.query || {};

    return {
      printId: query.printId || 'unknown',
      chapterId: query.chapterId || '0',
      chapterType: ChapterTypeEnum.is(query.chapterType)
        ? query.chapterType
        : 'unknown',
      startPage: parseInt(query.startPage || '1', 10) || 1,
      startPostion: query.startPosition === 'right' ? 'right' : 'left',
      coverMode: (query.coverMode as CoverModeType) || undefined,
      spineWidth: parseFloat(query.spineWidth || '0') || undefined
    };
  }

  async loadChapter() {
    const { printId, chapterId } = this.state.params;
    const basePath = config.env.development ? BASE_PATH_DEV : BASE_PATH_PROD;

    try {
      // fetch export JSON
      const response = await fetch(
        basePath + '/' + printId + '/chapter-' + chapterId + '.json'
      );

      const json = await response.json();

      if (process.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log('loadChapter result:', printId, chapterId, json);
      }

      this.setState({
        data: json,
        loadingState: 'ready'
      });
    } catch (err) {
      console.error('loadChapter error:', err);

      this.setState({
        loadingState: 'error'
      });
    }
  }

  renderChapter() {
    const {
      params,
      params: { chapterType },
      data
    } = this.state;

    if (!data) {
      return this.renderMessage('no chapter data present');
    }

    const chapter = createChapterModel(data.chapter);
    if (!chapter && chapterType !== 'imprint') {
      return this.renderMessage('chapter missing');
    }

    switch (chapterType) {
      case 'factsheet':
      case 'teacher_factsheet':
        return (
          <PrintFactsheetComponent
            params={params}
            chapter={chapter!}
            data={data}
          />
        );

      case 'yearbook':
        return (
          <PrintYearbookChapterComponent
            params={params}
            chapter={chapter!}
            data={data}
          />
        );

      case 'quote':
        return (
          <PrintQuotesChapterComponent
            params={params}
            chapter={chapter!}
            data={data}
          />
        );

      case 'ranking':
        return (
          <PrintRankingsChapterComponent
            params={params}
            chapter={chapter!}
            data={data}
          />
        );

      case 'table_of_contents':
        return (
          <PrintTocChapterComponent
            params={params}
            chapter={chapter!}
            data={data}
          />
        );

      case 'text':
        return (
          <PrintTextChapterComponent
            params={params}
            chapter={chapter!}
            data={data}
          />
        );

      case 'album':
        return (
          <PrintAlbumPageComponent
            params={params}
            chapter={chapter!}
            data={data}
          />
        );

      case 'sponsor':
        return (
          <PrintSponsorPageComponent
            params={params}
            chapter={chapter!}
            data={data}
          />
        );

      case 'imprint':
        return <PrintImprintComponent params={params} data={data} />;

      case 'cover':
        return (
          <PrintCoverComponent params={params} chapter={chapter!} data={data} />
        );

      default:
        return this.renderMessage('chapter type not supported');
    }
  }

  renderMessage(message: string) {
    return <PrintExportErrorComponent message={message} />;
  }

  render() {
    const { loadingState } = this.state;

    switch (loadingState) {
      case 'loading':
      case 'error':
        return this.renderMessage('Print state: ' + loadingState);

      case 'ready':
        return this.renderChapter();

      default:
        return this.renderMessage('Print state: not initialized');
    }
  }
}

export default PrintChapterContainer;
