import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { UploadAgeForm } from 'api/NuggitApi';
import { TextileAppState } from 'api/textile_deals/fetchTextileAppState';
import {
  TextileCheckout,
  createTextileCheckout,
  finishTextileCheckout,
  getTextileCheckout,
  updateTextileCheckout,
  uploadParentalApprovalTextile
} from 'api/textile_deals/fetchTextileCheckout';

export const useTextileCheckoutQuery = (textileOrderId?: number) => {
  return useQuery({
    queryKey: ['textile_checkout', textileOrderId],
    queryFn: () => getTextileCheckout(textileOrderId!),
    enabled: !!textileOrderId
  });
};

export const useCreateTextileCheckoutQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (textileOrderId: number) =>
      createTextileCheckout(textileOrderId),
    onMutate: async (_textileOrderId) => {
      await queryClient.cancelQueries(['textile_app_state', _textileOrderId]);
      const previousState:
        | TextileAppState
        | undefined = queryClient.getQueryData([
        'textile_app_state',
        _textileOrderId
      ]);

      if (!previousState) {
        return;
      }

      const newState: TextileAppState = {
        ...previousState,
        textile_order: {
          ...previousState.textile_order,
          order_state: 'order_started'
        }
      };
      queryClient.setQueryData(['textile_app_state', _textileOrderId], newState);

      return { previousState };
    },
    onError: (err, _textileOrderId, context) => {
      queryClient.setQueryData(
        ['textile_app_state', _textileOrderId],
        context?.previousState
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['textile_app_state', textileOrderId]);
    }
  });
};

export const useUpdateTextileCheckoutQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { patch: any; textileOrderId: number }) =>
      updateTextileCheckout(data.patch, data.textileOrderId),
    onMutate: async (data) => {
      await queryClient.cancelQueries([
        'textile_checkout',
        data.textileOrderId
      ]);
      const previousState:
        | TextileCheckout
        | undefined = queryClient.getQueryData([
        'textile_checkout',
        data.textileOrderId
      ]);

      if (!previousState) {
        return;
      }

      let newData = { ...data.patch };

      if (data.patch.context === 'address') {
        newData = { ...newData, shipping_address: true, billing_address: true };

        if (!data.patch.billing_address_set) {
          const billing_data = {};
          const fieldMap: { [key: string]: string } = {
            shipping_address_extra: 'billing_address_extra',
            shipping_city: 'billing_city',
            shipping_housenumber: 'billing_housenumber',
            shipping_name: 'billing_name',
            shipping_street: 'billing_street',
            shipping_zip: 'billing_zip'
          };

          Object.keys(data.patch).forEach((field) => {
            if (fieldMap[field]) {
              // @ts-ignore
              billing_data[fieldMap[field]] = data.patch[field];
            }
          });

          newData = { ...newData, ...billing_data };
        }
      }

      if (data.patch.context === 'age') {
        if (!data.patch.minor) {
          newData = { ...newData, age_verified: true };
        } else {
          newData = { ...newData, age_verified: false };
        }
      }

      const newState: TextileCheckout = {
        ...previousState,
        ...newData
      };
      queryClient.setQueryData(
        ['textile_checkout', data.textileOrderId],
        newState
      );

      return { previousState };
    },
    onError: (err, patch, context) => {
      queryClient.setQueryData(
        ['textile_checkout', patch.textileOrderId],
        context?.previousState
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['textile_checkout', textileOrderId]);
    }
  });
};

export const useUploadTextileParentalApprovalQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (data: { patch: UploadAgeForm; textileOrderId: number }) =>
      uploadParentalApprovalTextile(data.patch, data.textileOrderId),
    onMutate: async (data) => {
      await queryClient.cancelQueries([
        'textile_checkout',
        data.textileOrderId
      ]);
      const previousState:
        | TextileCheckout
        | undefined = queryClient.getQueryData([
        'textile_checkout',
        data.textileOrderId
      ]);

      if (!previousState) {
        return;
      }

      const newState: TextileCheckout = {
        ...previousState,
        parental_approval: 'uploaded'
      };
      queryClient.setQueryData(
        ['textile_checkout', data.textileOrderId],
        newState
      );

      return { previousState };
    },
    onError: (err, data, context) => {
      queryClient.setQueryData(
        ['textile_checkout', data.textileOrderId],
        context?.previousState
      );
    },
    onSettled: () => {
      queryClient.invalidateQueries(['textile_checkout', textileOrderId]);
    }
  });
};

export const useFinishTextileCheckoutQuery = (textileOrderId?: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (textileOrderId: number) =>
      finishTextileCheckout(textileOrderId),
    onMutate: async (_textileOrderId) => {
      await queryClient.cancelQueries(['textile_app_state', _textileOrderId]);
      const previousState:
        | TextileAppState
        | undefined = queryClient.getQueryData([
        'textile_app_state',
        _textileOrderId
      ]);

      if (!previousState) {
        return;
      }

      const newState: TextileAppState = {
        ...previousState,
        textile_order: {
          ...previousState.textile_order,
          order_state: 'order_placed'
        }
      };
      queryClient.setQueryData(['textile_app_state', _textileOrderId], newState);

      console.log("Now im Mutating")

      return { previousState };
    },
    onError: (err, _textileOrderId, context) => {
      queryClient.setQueryData(
        ['textile_app_state', _textileOrderId],
        context?.previousState
      );
    },
    onSettled: () => {
      console.log("Now im Settled")
      queryClient.invalidateQueries(['textile_app_state', textileOrderId]);
    }
  });
};
